a {
    color: #333;
    font-size: 14px;
    text-decoration: none;
    margin: 15px 0;
}

form {
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 50px;
    height: 100%;
    text-align: center;
}

input {
    background-color: #eee;
    border: none;
    padding: 12px 15px;
    margin: 8px 0;
    width: 100%;
    outline-color: darkgrey;
}

.container {
    border-radius: 10px;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    position: relative;
    overflow: hidden;
    width: 768px;
    max-width: 100%;
    min-height: 480px;
}

.form-container {
    position: absolute;
    top: 0;
    height: 100%;
}

.sign-in-container {
    left: 0;
    width: 50%;
    z-index: 2;
}

.overlay-container {
    position: absolute;
    top: 0;
    left: 50%;
    width: 50%;
    height: 100%;
    overflow: hidden;
    z-index: 100;
}

.overlay {
    background: #2d5d98;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0 0;
    color: #ffffff;
    position: relative;
    left: -100%;
    height: 100%;
    width: 200%;
    transform: translateX(0);
}

.overlay-panel {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 40px;
    text-align: center;
    top: 0;
    height: 100%;
    width: 50%;
    transform: translateX(0);
}

.overlay-right {
    right: 0;
    transform: translateX(0);
}

@media (max-width: 960px) {
    .container {
        width: 100%;
        max-width: 350px;
        margin: 40px;
    }
    .overlay-container {
        display: none;
    }
    .sign-in-container {
        width: 100%;
        max-width: 350px;
    }

    .logo {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        background-color: #2d5d98;
        padding: 20px 0;
    }
    .logo img {
        margin-top: 10px;
        margin-bottom: 10px;
    }
}
