:root {
    --white: #ffffff;
    --black: #000000;

    --logo-background: #2d5d98;

    --font-code: 'Roboto Mono', monospace;
    --font-text: 'Roboto', sans-serif;
}

html {
    font-family: var(--font-text);
    font-size: 62.5%;
    box-sizing: border-box;
}

body {
    background: var(--white);
    color: var(--primary-500);
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
}

p,
ul li,
a,
input,
textarea,
article,
td,
th,
button {
    line-height: 2.4rem;
    font-size: 1.4rem;
}

*,
::after,
::before {
    box-sizing: border-box;
}

*,
::after,
::before {
    box-sizing: inherit;
}

/* .MuiInputBase-root .Mui-disabled {
    color: rgba(0, 0, 0, 1);
}

.MuiChip-root.Mui-disabled {
    opacity: 0.8 !important;
}

.MuiFormControlLabel-label.Mui-disabled {
    color: rgba(0, 0, 0, 1) !important;
} */
